import useGameStore from "../lib/store/GameStore";
import { useEffect, useState } from "react";

const customAds = [
  {
    desktopImage: "/img/partners/insideword/trivia-desktop.png",
    mobileImage: "/img/partners/insideword/trivia-mobile.png",
    link: "https://bit.ly/3TFUQLf",
    alt: "AFL trivia",
  },
  // {
  //   desktopImage: "/img/partners/insideword/brownlow-desktop.png",
  //   mobileImage: "/img/partners/insideword/brownlow-mobile.png",
  //   link: "https://bit.ly/4en9RJV",
  //   alt: "AFL brownlow",
  // },
  {
    desktopImage: "/img/partners/insideword/gf-desktop.png",
    mobileImage: "/img/partners/insideword/gf-mobile.png",
    link: "https://bit.ly/47xxLQN",
    alt: "AFL Grand Final",
  },
  {
    desktopImage: "/img/partners/insideword/dailybets-desktop.png",
    mobileImage: "/img/partners/insideword/dailybets-mobile.png",
    link: "https://bit.ly/3ZmNj7T",
    alt: "AFL best bets",
  },
];

export default function AdSlot() {
  const [showCustomAd, user] = useGameStore((state) => [
    state.showCustomAd,
    state.user,
  ]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 414);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (showCustomAd) {
      const randomIndex = Math.floor(Math.random() * customAds.length);
      setSelectedAd(customAds[randomIndex]);
    }
  }, [showCustomAd, user]);

  const userOver18 = !user || user?.birthYear <= 2005;

  if (userOver18 && showCustomAd && selectedAd) {
    const imageSrc = isMobile
      ? selectedAd.mobileImage
      : selectedAd.desktopImage;
    return (
      <a href={selectedAd.link} target="_blank" rel="noopener noreferrer">
        <img
          src={imageSrc}
          alt={selectedAd.alt}
          className="mx-auto h-[50px] max-h-[50px] sm:h-[90px] sm:max-h-[90px]"
        />
      </a>
    );
  }

  return (
    <ins
      className="adsbygoogle h-[50px] max-h-[50px] sm:h-[90px] sm:max-h-[90px]"
      style={{
        display: "block",
      }}
      data-ad-client="ca-pub-4746633404428334"
      data-ad-slot="4370566560"
    ></ins>
  );
}
